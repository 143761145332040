import "./social-media.scss";

const SocialMedia = () => {
  return (
    <div className="social-media-list">
      <div className="social-media">
        <a href="https://www.instagram.com/__mobina__hoseini/" target="_blank" rel="noreferrer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_232_96)">
              <path
                className="img"
                d="M12.0262 0.00415039C14.7874 0.00415039 17.5486 0.00415039 20.3097 0.00415039C22.5009 0.00415039 23.997 1.50996 23.997 3.70944C23.997 9.24474 23.997 14.7796 23.997 20.3139C23.997 22.5051 22.4972 24.0011 20.294 24.0011C14.7597 24.0011 9.22485 24.0011 3.68954 24.0011C1.49981 24.0011 0 22.5013 0 20.2981C0 14.7638 0 9.229 0 3.69369C0 1.50396 1.50581 0.00415039 3.70529 0.00415039C6.47944 0.00415039 9.25309 0.00415039 12.0262 0.00415039ZM11.9985 22.5883C14.7597 22.5883 17.5208 22.5883 20.282 22.5883C21.733 22.5883 22.5812 21.7349 22.5812 20.2779C22.5812 14.7676 22.5812 9.25774 22.5812 3.74843C22.5812 2.26737 21.7375 1.42372 20.261 1.42372H3.73378C2.25872 1.42372 1.41657 2.27112 1.41582 3.75368C1.41582 9.25299 1.41582 14.7503 1.41582 20.2456C1.41582 21.7514 2.25347 22.5906 3.75553 22.5913C6.50419 22.5903 9.25184 22.5893 11.9985 22.5883Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M18.3196 11.9883C18.3227 13.2385 17.9549 14.4616 17.2627 15.5028C16.5705 16.5439 15.585 17.3564 14.431 17.8373C13.2769 18.3182 12.0061 18.446 10.7794 18.2045C9.5527 17.963 8.4252 17.363 7.53956 16.4805C6.65393 15.598 6.04996 14.4727 5.80408 13.2468C5.5582 12.021 5.68146 10.7498 6.15826 9.59401C6.63507 8.43825 7.44399 7.44989 8.48267 6.75399C9.52135 6.05808 10.7431 5.68592 11.9934 5.68458C13.6681 5.68179 15.2754 6.34432 16.4618 7.52645C17.6481 8.70859 18.3164 10.3135 18.3196 11.9883ZM12.0489 7.08465C9.38669 7.02166 7.15721 9.20314 7.08147 11.9456C7.00648 14.6025 9.18121 16.8349 11.9319 16.9219C14.5978 17.0067 16.867 14.7877 16.9195 12.0475C16.9712 9.37787 14.7853 7.14915 12.0489 7.08465Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M19.0382 7.07121C18.763 7.07389 18.4901 7.02209 18.235 6.91882C17.9799 6.81555 17.7478 6.66284 17.552 6.46949C17.3562 6.27613 17.2006 6.04595 17.0942 5.7922C16.9877 5.53845 16.9325 5.26615 16.9317 4.99097C16.9293 4.71258 16.9821 4.43646 17.0871 4.17858C17.192 3.9207 17.3469 3.68615 17.543 3.48849C17.7391 3.29082 17.9723 3.13395 18.2294 3.02694C18.4864 2.91992 18.762 2.86488 19.0405 2.86499C19.5964 2.87299 20.1272 3.098 20.5195 3.492C20.9118 3.88599 21.1346 4.41775 21.1402 4.97373C21.1374 5.52994 20.9149 6.06252 20.5212 6.4554C20.1275 6.84829 19.5944 7.06964 19.0382 7.07121ZM19.0382 5.66964C19.2209 5.66786 19.3954 5.59379 19.5235 5.46365C19.6517 5.33351 19.7231 5.15789 19.7221 4.97523C19.7245 4.88458 19.7087 4.79439 19.6755 4.71001C19.6423 4.62564 19.5924 4.54882 19.5288 4.48415C19.4653 4.41947 19.3893 4.36827 19.3056 4.33359C19.2218 4.29892 19.1319 4.28147 19.0412 4.28231C18.8572 4.28231 18.6808 4.3554 18.5507 4.48548C18.4206 4.61557 18.3475 4.79201 18.3475 4.97598C18.3475 5.15995 18.4206 5.33638 18.5507 5.46647C18.6808 5.59656 18.8572 5.66964 19.0412 5.66964H19.0382Z"
                fill="#272727"
              />
            </g>
            <defs>
              <clipPath id="clip0_232_96">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.00415039)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>

      <div className="social-media">
        <a href="https://www.linkedin.com/in/mobina-hoseini/" target="_blank" rel="noreferrer"> 
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_232_103)">
              <path
                className="img"
                d="M23.9992 3.71918C23.9985 1.49736 22.506 0.00415039 20.2827 0.00415039H3.71653C1.49321 0.00415039 0 1.49811 0 3.72068C0 6.48121 0 9.24174 0 12.0023C0 14.7628 0 17.5236 0 20.2846C0 22.5057 1.49396 23.9981 3.71653 23.9981C9.23909 23.9981 14.7612 23.9981 20.2827 23.9981C22.5045 23.9981 23.9963 22.5049 23.997 20.2824C24 14.7593 24.0007 9.23824 23.9992 3.71918ZM22.584 20.2516C22.584 21.7359 21.7429 22.5829 20.2685 22.5837C14.7589 22.5837 9.24909 22.5837 3.73901 22.5837C2.2608 22.5837 1.41525 21.7381 1.41525 20.2599C1.41525 14.7513 1.41525 9.24249 1.41525 3.73342C1.41675 2.26045 2.2653 1.4209 3.74801 1.42015C6.50904 1.42015 9.27032 1.42015 12.0319 1.42015C14.7679 1.42015 17.504 1.42015 20.24 1.42015C21.7437 1.42015 22.5818 2.2582 22.5818 3.76265C22.5848 9.25973 22.5855 14.7561 22.584 20.2516Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M14.3798 9.99708C14.2912 10.0108 14.2006 10.0012 14.1167 9.96935C13.999 9.93562 13.8874 9.84492 13.7727 9.84417C12.5853 9.83442 11.3987 9.83742 10.2113 9.83967C10.1167 9.84415 10.0223 9.85365 9.92871 9.86815V19.7629H14.1055V19.3326C14.1055 17.8956 14.1055 16.4586 14.1055 15.0224C14.1002 14.8484 14.1235 14.6747 14.1745 14.5082C14.2757 14.2196 14.5088 14.0749 14.8116 14.0757C15.1145 14.0764 15.3468 14.2256 15.4458 14.5119C15.4957 14.6789 15.5185 14.8527 15.5132 15.0269C15.5185 16.4639 15.5132 17.9001 15.5132 19.3371C15.5132 19.4803 15.5275 19.6242 15.535 19.7749H19.6803C19.6978 19.7283 19.7103 19.6801 19.7178 19.631C19.7178 17.7315 19.7882 15.829 19.69 13.934C19.5648 11.3981 17.0987 9.23249 14.3798 9.99708ZM18.2905 18.3476H16.9502C16.9427 18.2037 16.9315 18.0605 16.93 17.9166C16.9158 16.781 16.9487 15.6431 16.8783 14.5112C16.8131 13.4505 15.8903 12.6724 14.8259 12.6672C13.7434 12.6619 12.8139 13.4408 12.745 14.5217C12.6753 15.6288 12.7075 16.742 12.6962 17.8522C12.6962 18.0111 12.6962 18.17 12.6962 18.3506H11.3327V11.2849H12.6917V12.6649C13.0171 12.422 13.2449 12.2152 13.5036 12.0652C13.9533 11.8036 14.3933 11.4753 14.8828 11.3509C16.457 10.9498 18.1264 12.117 18.262 13.7991C18.3827 15.296 18.2905 16.814 18.2905 18.3476Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M4.30176 19.7659H8.4568V9.8667H4.30176V19.7659ZM5.69752 11.2812H7.0393V18.3514H5.69752V11.2812Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M8.45564 4.24536H4.28711V8.41465H8.45564V4.24536ZM7.04639 7.01514H5.71285V5.65012H7.04639V7.01514Z"
                fill="#272727"
              />
            </g>
            <defs>
              <clipPath id="clip0_232_103">
                <rect
                  width="24"
                  height="23.9955"
                  fill="white"
                  transform="translate(0 0.00415039)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      {/* <div className="social-media">
        <a href="behance">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_232_111)">
              <path
                className="img"
                d="M11.9764 23.9953C9.21468 23.9953 6.45348 23.9953 3.69277 23.9953C1.4993 23.9938 0 22.493 0 20.2935C0 14.7606 0 9.22668 0 3.69177C0 1.49905 1.5038 -0.000244141 3.70326 -0.000244141C9.23817 -0.000244141 14.7728 -0.000244141 20.3072 -0.000244141C22.5015 -0.000244141 23.9985 1.49905 23.9993 3.70077C23.9993 9.23468 23.9993 14.7686 23.9993 20.3025C23.9993 22.496 22.5 23.9923 20.2982 23.9938C17.524 23.9963 14.7501 23.9968 11.9764 23.9953ZM22.5847 11.9941C22.5847 9.23343 22.5847 6.47272 22.5847 3.71202C22.5847 2.26145 21.7308 1.41359 20.2735 1.41284C14.7641 1.41284 9.25441 1.41284 3.7445 1.41284C2.26319 1.41284 1.42058 2.2562 1.42058 3.73226C1.42058 9.24167 1.42058 14.7508 1.42058 20.2598C1.42058 21.7351 2.26769 22.5769 3.75049 22.5769C9.24792 22.5769 14.7453 22.5769 20.2428 22.5769C21.7496 22.5769 22.5884 21.7396 22.5892 20.238C22.5872 17.4908 22.5857 14.7428 22.5847 11.9941Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M10.3732 12.0046C10.8935 12.5376 11.2203 13.1471 11.287 13.8907C11.4152 15.3211 10.3432 16.6929 8.91664 16.8743C8.54316 16.9135 8.16765 16.93 7.79217 16.9238C6.73066 16.9298 5.66841 16.9193 4.60616 16.9305C4.34678 16.9305 4.25008 16.8556 4.26282 16.5902C4.28156 16.2034 4.26732 15.8158 4.26732 15.4283V7.15662C4.32579 7.11314 4.33554 7.0989 4.34228 7.0989C5.91655 7.11014 7.5043 6.97745 9.05308 7.17386C11.0891 7.42949 11.9722 10 10.6926 11.6185C10.5989 11.7422 10.4954 11.8622 10.3732 12.0046ZM5.68416 8.49399C5.68416 9.39357 5.67891 10.2534 5.6939 11.1125C5.6939 11.1755 5.83409 11.2887 5.9098 11.2894C6.79663 11.2969 7.68497 11.3209 8.56955 11.2737C8.92326 11.2568 9.25711 11.1052 9.50257 10.85C9.74804 10.5948 9.88652 10.2553 9.88959 9.90118C9.89266 9.54708 9.76009 9.20523 9.51908 8.94579C9.27807 8.68635 8.94691 8.52898 8.59354 8.50599C7.64674 8.45651 6.69918 8.49399 5.68416 8.49399ZM5.68416 12.6973C5.68416 13.6044 5.68416 14.4627 5.69165 15.3211C5.69165 15.3855 5.80185 15.5047 5.86107 15.5047C6.82212 15.5047 7.78692 15.5354 8.74347 15.4597C9.42715 15.405 9.89568 14.7678 9.88744 14.0931C9.87844 13.4019 9.37917 12.791 8.679 12.7393C7.87088 12.68 7.05601 12.707 6.24489 12.6973C6.07247 12.695 5.90155 12.6973 5.68341 12.6973H5.68416Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M14.237 14.103C14.5212 14.8069 14.9755 15.2432 15.6374 15.4201C16.6502 15.6907 17.444 15.3151 18.061 14.4703L19.2432 15.1495C18.9433 15.7282 18.5018 16.133 17.9688 16.4426C17.3035 16.8257 16.531 16.9803 15.7696 16.8826C15.0081 16.7849 14.2997 16.4403 13.7527 15.9017C13.2057 15.363 12.8503 14.6599 12.7409 13.9C12.6315 13.1402 12.7742 12.3654 13.1471 11.6943C13.8967 10.3487 15.489 9.62979 16.9755 9.97013C17.7318 10.1381 18.4113 10.5514 18.9084 11.1456C19.4054 11.7399 19.6921 12.4818 19.7237 13.2558C19.7575 14.1015 19.7575 14.1015 18.9298 14.1015H14.237V14.103ZM18.1352 12.6689C17.9141 11.8548 17.0857 11.2925 16.1719 11.3083C15.2956 11.3233 14.4807 11.908 14.2978 12.6689H18.1352Z"
                fill="#272727"
              />
              <path
                className="img"
                d="M19.0175 7.10278V8.45215H13.4453V7.10278H19.0175Z"
                fill="#272727"
              />
            </g>
            <defs>
              <clipPath id="clip0_232_111">
                <rect
                  width="24"
                  height="23.9963"
                  fill="white"
                  transform="translate(0 -0.000244141)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div> */}
      <div className="social-media">
        <a href="https://dribbble.com/mobina-hoseini" target="_blank" rel="noreferrer">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_232_119)">
              <path
                className="img"
                d="M24 13.0566C23.9967 16.2262 22.7344 19.2647 20.4909 21.5037C18.2474 23.7427 15.2063 24.9989 12.0367 24.9958C5.40482 24.9898 -0.0106604 19.6017 1.57583e-05 13.0232C0.0106919 6.43001 5.4582 0.99585 12.0514 0.99585C18.6306 0.99585 24.0074 6.42601 24 13.0566ZM13.871 14.4378C13.7209 14.4678 13.6348 14.4758 13.5547 14.5045C11.948 15.0237 10.4613 15.7723 9.15082 16.8433C7.68751 18.0337 6.56585 19.4703 5.98066 21.2932C5.89526 21.5601 5.95464 21.6936 6.18084 21.835C8.99401 23.6144 11.9967 24.0394 15.1888 23.1102C15.4171 23.0434 15.5138 22.95 15.4758 22.6945C15.2316 21.0543 15.0501 19.4029 14.7485 17.7741C14.5396 16.6491 14.1713 15.5568 13.871 14.4378ZM4.70687 20.5806C6.26558 16.5837 9.40838 14.3891 13.3332 13.0586C13.125 12.5648 12.9135 12.1131 12.7453 11.6453C12.6326 11.3317 12.4838 11.2743 12.1615 11.3644C8.86256 12.2846 5.50424 12.5274 2.09988 12.1911C1.8937 12.1704 1.68618 12.1711 1.486 12.1624C0.973548 14.7367 2.7965 19.4789 4.70687 20.5806ZM1.67217 10.7378C5.16994 11.1309 8.56162 10.9227 11.9333 9.9785C10.7574 7.69277 9.29136 5.56844 7.57141 3.65822C7.4106 3.48006 7.28849 3.5281 7.12168 3.61685C5.42877 4.50196 4.00583 5.82716 3.00268 7.45292C2.36908 8.46094 1.91865 9.57302 1.67217 10.7378ZM8.82119 2.94225C10.5828 4.93335 12.0862 7.1387 13.2958 9.50608C15.5552 8.7821 18.3296 7.04256 19.1711 5.26031C16.6461 2.71605 12.377 1.69247 8.82119 2.94225ZM16.8777 22.3949C17.0358 22.3148 17.1479 22.2654 17.2527 22.204C20.1637 20.4763 21.9062 17.9429 22.48 14.6039C22.526 14.337 22.4306 14.2396 22.1891 14.2036C21.1408 14.0568 20.0972 13.8506 19.0436 13.7705C17.7758 13.6731 16.504 13.7959 15.2115 14.0788C16.1022 16.773 16.6616 19.5655 16.8777 22.3949ZM20.218 6.33393C18.6346 8.5946 16.3772 9.83704 13.9391 10.8212C14.1699 11.3777 14.3968 11.8789 14.5843 12.3933C14.6804 12.6602 14.8012 12.7209 15.0794 12.6662C16.9951 12.2824 18.9633 12.2373 20.8946 12.5328C21.4451 12.6182 21.9929 12.7209 22.5834 12.823C22.51 10.3882 21.7313 8.2543 20.218 6.33393Z"
                fill="#272727"
              />
            </g>
            <defs>
              <clipPath id="clip0_232_119">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.99585)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div className="social-media">
        <a href="https://github.com/mobinahoseini/" target="_blank" rel="noreferrer">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 25"
          >
            <g>
              <path
                className="img"
                d="M13,0.2C6,0.2,0.2,6,0.2,13C0.2,20,6,25.7,13,25.7S25.8,20,25.8,13C25.8,6,20,0.2,13,0.2z M13,24.2
	            	c-6.2,0-11.2-5-11.2-11.3c0-6.2,5-11.2,11.2-11.2s11.2,5,11.2,11.2C24.2,19.2,19.2,24.2,13,24.2z"
              />
              <path
                className="img"
                d="M19.6,5.5c-0.1-0.2-0.3-0.4-0.5-0.4c-0.3-0.1-1.4-0.2-3.5,1.1c-1.8-0.4-3.6-0.4-5.3,0C8.2,4.9,7.2,5,6.9,5.1
	             	C6.6,5.2,6.5,5.3,6.4,5.5c-0.4,1-0.5,2-0.2,3.1C5.4,9.5,5,10.5,5,11.7c0,4.2,2.4,5.5,4.6,6c0,0,0,0.1,0,0.1
            		c-0.2,0.4-0.2,0.9-0.2,1.3v0.1c-1.8,0.4-3.3-0.3-4.2-2.1c-0.2-0.4-0.6-0.5-1-0.3c-0.4,0.2-0.5,0.6-0.3,1c1,2,2.6,3,4.6,3
	            	c0.3,0,0.6,0,0.9-0.1v0.6c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8l0-2.3c0-0.3,0-0.5,0.1-0.8c0.1-0.2,0.2-0.5,0.4-0.7
	            	c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.3-0.5-0.6-0.5c-2.3-0.3-4.5-1-4.5-4.7c0-0.9,0.3-1.8,1-2.4c0.2-0.2,0.3-0.5,0.2-0.8
            		C7.5,7.9,7.4,7.2,7.6,6.6c0.4,0.1,1.1,0.3,2.2,1c0.2,0.1,0.4,0.2,0.6,0.1c1.7-0.4,3.5-0.4,5.2,0c0.2,0.1,0.4,0,0.6-0.1
	            	c1.1-0.7,1.8-0.9,2.2-1c0.2,0.6,0.1,1.3-0.1,1.9c-0.1,0.3,0,0.6,0.2,0.8c0.6,0.6,1,1.5,1,2.4c0,3.7-2.2,4.5-4.5,4.7
	            	c-0.3,0-0.5,0.2-0.6,0.5c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5,0.1,0.8v2.2c0,0.4,0.3,0.8,0.8,0.8
	            	s0.8-0.3,0.8-0.8l0-2.2c0-0.5,0-1-0.2-1.4c0,0,0-0.1,0-0.1c1.9-0.4,4.6-1.6,4.6-6c0-1.1-0.4-2.2-1.1-3.1C20.1,7.6,20,6.5,19.6,5.5z
	            	"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
